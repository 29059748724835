<!--
 * @Author: zhangyutong
 * @Date: 2021-08-16 19:25:47
 * @LastEditTime: 2022-04-13 16:36:57
 * @LastEditors: Please set LastEditors
 * @FilePath: \lightning-web\src\views\commonObjects\maketingSocial\maketingSocialListShow.vue
-->
<template>
  <div class="objectTableMain">
    <div class="object_box">
      <div class="pull-left topContent">
        <el-breadcrumb
          separator-class="el-icon-arrow-right"
          style="margin: 10px 10px 6px 10px"
        >
          <el-breadcrumb-item @click.native="goUrl(-1)">{{
            $store.state.channel.tabName
          }}</el-breadcrumb-item>
          <el-breadcrumb-item @click.native="goUrl(-1)">{{
            $store.state.channel.bigTitle
          }}</el-breadcrumb-item>
          <!-- <el-breadcrumb-item>社交帖子</el-breadcrumb-item> -->
        </el-breadcrumb>
        <!-- 社交帖子 -->
        <div class="header-title">{{$t('market.name.social.post')}}</div>
      </div>
      <div class="pull-left topContent pl-10">
        <statistics
          :total="totalSize"
          :isQueryCount="true"
          :time-interval="timeInterval"
          :show-checked-length="false"
          @refresh="refresh"
        ></statistics>
      </div>
      <table-panel
        class="text-center"
        ref="tablePanel"
        :border="true"
        :show-lock="false"
        :table-height="tableHeight"
        :checked="true"
        :pict-loading="pictLoading"
        :table-attr="tableAttr"
        :page-obj="pageObj"
        :use-hyperlinks="false"
        :tableButton="operationButtonList"
        @show="show"
      >
      </table-panel>
    </div>
    <!-- 分页 -->
    <div class="block">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="pageCount"
        :page-sizes="pageSizes"
        :page-size="pageSize"
        layout="sizes, prev, pager, next"
        :total="totalSize"
      >
      </el-pagination>
      <span style="margin-left: 10px">
        {{ $t("label.sales.dashboard.number") }} {{ pageCount }}
        {{ $t("label.tabpage.page") }}
      </span>
    </div>
    <!-- soical详情 -->
    <detailSoical ref="detailSoical"></detailSoical>
  </div>
</template>

<script>
import { TablePanel, Statistics } from "@/components/index";
import detailSoical from '@/views/social/components/detailSoical.vue'
import * as marketChannel from '@/views/marketList/api.js'
export default {
  components: { TablePanel, Statistics, detailSoical },
  data() {
    return {
      //分页
      dataList: [],
      pageSize: 50, // 每页展示数据条数
      pageSizes: [10, 25, 50, 100, 200], //每页显示个数
      totalSize: '',
      lastRefresh: new Date(), // 最后一次更新时间
      timer: '',
      timeInterval: '',
      tableHeight: '',
      tableAttr: [
        {

          "schemefieldName": "socialmedianame",
          "nameLabel": "帖子发送人",
        },
        {

          "schemefieldName": "textcontent",
          "nameLabel": "内容",

        },
        {
          "schemefieldName": "poststype",
          "nameLabel": "状态",
        },
        {
          "schemefieldName": "socialtype",
          "nameLabel": "发送平台",
        },
        {
          "schemefieldLength": "255",
          "schemefieldType": "D",
          "schemefieldName": "senddate",
          "nameLabel": "发送日期",
        }
      ],
      objId: this.$route.params.id,
      // this.$i18n.t('label.emailtocloudcc.button.view')
      operationButtonList: {
        buttonList: [
          {
            label: '查看',
            notShow: true,
            action: "show",
            cssClass: "small"
          }
        ],
        align: "center",
        width: "80"
      },
      pageObj: {
        dataList: [],
      },
      pictLoading: false,
      searchKeyWord: "",
    };
  },
  computed: {
    // eslint-disable-next-line vue/no-dupe-keys
    pageCount() {
      let { totalSize, pageSize } = this
      return Math.ceil(totalSize / pageSize)
    },
  },
  created() {
    var _this = this;
    document.onkeydown = function () {
      //按下回车提交
      let key = window.event.keyCode;
      //事件中keycode=13为回车事件
      if (key == 13) {
        _this.search();
      }
    };
    this.init()
  },
  mounted() {
    this.tableHeight = 465 //减去分页的高度
    // 计时器计算多久前更新
    this.timer = setInterval(() => {
      // 获取当前时间
      let currentTime = new Date();
      let interval = currentTime - this.lastRefresh;

      //计算出相差天数
      var days = Math.floor(interval / (24 * 3600 * 1000));
      //计算出小时数
      var leave1 = interval % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
      var hours = Math.floor(leave1 / (3600 * 1000));
      //计算相差分钟数
      var leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
      var minutes = Math.floor(leave2 / (60 * 1000));
      //计算相差秒数
      var leave3 = leave2 % (60 * 1000); //计算分钟数后剩余的毫秒数
      var seconds = Math.round(leave3 / 1000);
      hours = minutes > 30 ? hours + 1 : hours;
      if (days > 0) {
        this.timeInterval =
          hours > 0 ? `${days} ${this.$i18n.t('label.login.access.day')}${hours} ${this.$i18n.t('label_tabpage_hoursagoz')}` : `${days}${this.$i18n.t('label_tabpage_Sdfez')}`;
      } else if (hours > 0) {
        this.timeInterval = `${hours} ${this.$i18n.t('label_tabpage_hoursagoz')}`;
      } else if (minutes > 0) {
        this.timeInterval = `${minutes} ${this.$i18n.t('label_tabpage_minutesagoz')}`;
      } else {
        this.timeInterval = seconds > 43 ? this.$i18n.t('vue_label_commonobjects_view_secondsbefore', {
          seconds: seconds,
        }) : this.$i18n.t('label.notification.time1');
      }
    }, 1000);
  },
  methods: {
    show(val) {
      this.$refs.detailSoical.detailSoical({ id: val.data.id, posttype: '', linkedinuserid: '' })
    },
    showpage() {
      if (this.dataList === null && this.dataList === []) {
        this.totalSize = 0
      } else if (this.pageCount === 1) {
        this.pageObj.dataList = this.dataList.slice(0, this.pageSize)
      } else {
        let num = this.pageCount * this.pageSize
        let numNext = (this.pageCount - 1) * this.pageSize
        this.pageObj.dataList = this.dataList.slice(numNext, num)
      }
    },
    // 显示页数发生变化时
    handleSizeChange(val) {
      this.pageSize = val
      this.showpage()
    },
    // 当前页码发生变化时
    handleCurrentChange(val) {
      this.pageCount = val
      this.$refs.tablePanel.$refs.tablePanel.bodyWrapper.scrollTop = 0
      this.showpage()
    },
    goUrl(index) {
      this.$router.go(index)
    },
    // 刷新
    refresh() {
      this.init()
      this.lastRefresh = new Date();
      // 重置列表信息
      // 重置列表选中统计数据
      this.$refs.tablePanel.checkedList = [];
    },
    init() {
      this.searchKeyWord = "";
      this.pageObj.dataList = [];
      this.getLookupData();
    },
    // 搜索
    search() {
      this.page = 1;
      this.pageObj.dataList = [];
      this.getLookupData();
    },
    // 获取查找数据
    getLookupData() {
      this.pictLoading = true;
      marketChannel.getPostInfo({ orgid: this.$store.state.userInfoObj.orgId, campaignid: this.$route.query.id }).then((res) => {
        if (res.result) {
          // 页码为1时重新请求表头
          this.pictLoading = false
          this.$refs.tablePanel.objModify = true
          this.dataList = res.data.sociallist;
          this.dataList.forEach(element => {
            element.attachtype = true
          });
          this.totalSize = res.data.sociallist.length
          this.$refs.tablePanel.changeTableAttr(this.tableAttr);
          this.showpage()
        } else {
          this.$message({
            showClose: true,
            type: "error",
            message: res.returnInfo,
          })
        }
      });
    },
  },
  beforeDestroy() {
    // 在Vue实例销毁前，清除我们的定时器
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
}
</script>
<style lang='scss' scoped>
.block {
  background: #fff;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  z-index: 9;
}
.header-title {
  font-family: PingFangSC-Medium;
  font-size: 16px;
  color: #080707;
  letter-spacing: 0;
  line-height: 30px;
  margin-left: 10px;
}
.topContent {
  width: 100%;
}
.objectTableMain {
  border-left: none;
  border-right: none;
  border-radius: 3px;
  position: relative;
  height: 100%;
  padding: 10px 10px 10px 10px;
  display: flex;
  flex-direction: column;
  .object_box {
    border-radius: 3px;
    border: 1px solid #dedcda;
    // height: calc(100% - 50px);
    flex: 1;
    overflow: auto;
    background: #f5f5f5;
    display: flex;
    flex-direction: column;
  }
}
::v-deep .el-breadcrumb__inner {
   color: #006dcc;
   cursor: pointer;
}

::v-deep .el-breadcrumb__item:last-child .el-breadcrumb__inner{
 color: #006dcc;
   cursor: pointer;
}
</style>